#payee-name-spacing {
  margin: 1em auto 0 auto;
}
#payeeBtn {
  margin: auto;
  height: 3.5rem;
}

/* For Smart phones whose view ports are up 450px in width */
@media screen and (max-width: 450px) {
  #payeeBtn {
    margin: auto 0 auto 0;
    height: 1.5rem;
  }
}

/* For Smart phones whose view ports are up 340px in width
      Ex. Iphone 5/SE */
@media screen and (max-width: 340px) {
  #payeeBtn {
    margin: auto 0.5em auto 0;
    padding: 1.5em 0;
  }

  #payeeBtn > p {
    font-size: 85%;
    margin: -0.5em auto 2em auto;
  }
}
/* For Smart phones whose view ports are up 290px in width
      Ex. Galaxy Fold */
@media screen and (max-width: 290px) {
  #payeeBtn {
    margin-top: 0rem;
    margin-left: 4rem;
    height: 3.5rem;
  }
}
