/*.create-req-btn {*/
/*  margin: 1em auto;*/
/*}*/

/*.create-req-btn > p {*/
/*  font-size: 95%;*/
/*  text-align: center;*/
/*  margin: 0 -30%;*/
/*}*/

/* For Smart phones whose view ports are up 450px in width
@media screen and (max-width : 450px){
    .create-req-btn{
      margin: auto 0.5em auto auto;
    }
  
    .create-req-btn > p {
      font-size: 95%;
      text-align: center;
      margin: 0 -135%;
    }
  }
  
  /* For Smart phones whose view ports are up 340px in width
      Ex. Iphone 5/SE, Moto G4 */
/* @media screen and (max-width : 360px){
    .create-req-btn{
      margin: auto 0.5em auto auto;
    }
  
    .create-req-btn > p {
      font-size: 95%;
      text-align: center;
      margin: 0 -1.9rem;
    }
  }
   */
/* For Smart phones whose view ports are up 290px in width
      Ex. Galaxy Fold */
/* @media screen and (max-width : 290px){
    .create-req-btn{
      margin: auto 1.5em auto auto;
    }
  
    .create-req-btn > p {
      font-size: 85%;
      text-align: center;
      margin: 0 -10rem;
      padding: 0 10em;
    }
  }
   */
