#callBackTextBox {
  width: 100%;
}

.initial {
  width: 100% !important;
  border: solid 1px #e92243;
  background-color: #fff4f5;
}

textarea:focus {
  outline: none;
  border: solid 2px #e92243;
}

.blur {
  border: solid 1px #919191;
  background-color: white;
}

.edited:focus {
  border: solid 2px #28a3af !important;
}
