.para {
  color: #06748c;
}

.h3-Style2 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 0.6em;
}

.subtitle {
  text-align: center;
  font-size: 1.45em;
  margin-bottom: 0cm;
}
